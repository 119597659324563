<template>
  <div v-click-outside="hideMenus" id="home-menu">
    <div v-if="menus.length > 0" class="menu-button">
      <feather-icon @click="toggleMenu()" icon="MenuIcon"/>
    </div>
    <div :class="sidebarClass" id="menus-sidebar">
      <div class="menus-content">
        <feather-icon @click="toggleMenu()" class="close-icon" icon="XIcon"/>
        <ul>
          <li v-for="menu in menus">
            <router-link :to="menu.route">
              <fa-icon v-if="menu.icon" :icon="menu.icon"/>
              <span>{{ menu.title }}</span>
            </router-link>
          </li>
        </ul>
      </div>

    </div>
  </div>
</template>

<script>

import Vue from 'vue'

Vue.directive('click-outside', {
  bind: function (element, binding, vnode) {
    element.clickOutsideEvent = function (event) {  //  check that click was outside the el and his children
      if (!(element === event.target || element.contains(event.target))) { // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
        // binding.value(); run the arg
      }
    }
    document.body.addEventListener('click', element.clickOutsideEvent)
  },
  unbind: function (element) {
    document.body.removeEventListener('click', element.clickOutsideEvent)
  }
})

import MenusModule from '@/store/menus/menusModule'
import store from '@/store'

export default {
  name: 'home-menus',
  data() {
    return {
      MENUS_STORE_MODULE_NAME: 'menusModule',
      menuOpen: false
    }
  },
  computed: {
    menus() {
      return this.$store.state[this.MENUS_STORE_MODULE_NAME].menus
    },
    sidebarClass() {
      return this.menuOpen ? 'active' : ''
    }
  },
  created() {
    if (!store.hasModule(this.MENUS_STORE_MODULE_NAME)) store.registerModule(this.MENUS_STORE_MODULE_NAME, MenusModule)
    this.getMenus()
  },
  methods: {
    getMenus() {
      this.$store.dispatch(`${this.MENUS_STORE_MODULE_NAME}/getActiveMenus`)
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen
    },
    hideMenus() {
      this.menuOpen = false
    }
  }
}
</script>

<style lang="scss">
#home-menu {

  .menu-button {
    position: relative;
    top: 7px;

    svg {
      color: #000;
      font-weight: bold;
      width: 2.2rem;
      height: 2.2rem;
      cursor: pointer;
    }
  }

  #menus-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 70%);
    height: 100vh;
    width: 20rem;
    transition: transform 0.5s ease;
    transform: translateX(-23rem);
    z-index: 999;

    &.active {
      transform: translateX(0);
    }

    .menus-content {
      display: flex;
      position: relative;

      .close-icon {
        position: absolute;
        right: 5px;
        top: 5px;
        color: #000;
        width: 1.6rem;
        height: 1.6rem;
        cursor: pointer;
      }

      ul {
        margin: 30px 0;
        padding: 0;
        list-style: none;
        color: #000;
        font-weight: bold;
        border: 1px solid #eee;
        width: 100%;

        li {
          border-bottom: 1px solid #eee;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 50px;
          padding: 0 15px;
          cursor: pointer;

          &:last-child {
            border-bottom: none
          }

          svg {
            opacity: 1;
          }

          span {
            opacity: 1;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #home-menu {
    #menus-sidebar {
      width: 100%;
      transform: translateX(-103%);
    }
  }
}
</style>
