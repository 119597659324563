<template>
  <div id="faq" class="auth-wrapper auth-v2">
    <div class="page-header">
      <Menus/>
      <router-link to="/" class="login">
        <fa-icon icon="home"/>
        <span>Գլխավոր</span>
      </router-link>
    </div>
    <div class="page-content">
      <div class="page-title">Հաճախ տրվող հարցեր</div>
      <div
          id="helpAccordion"
      >
        <app-collapse accordion>

          <app-collapse-item
              v-for="(faq, index) in faqs"
              :key="index"
              :title="faq.title"
          >
            <div v-html="faq.description" />
          </app-collapse-item>
        </app-collapse>
      </div>
    </div>
  </div>
</template>

<script>

import FaqModule from '@/store/faq/faqModule'
import store from "@/store"
import Menus from './../../../layouts/components/Menus'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  name: '',
  components: {
    Menus,
    AppCollapse,
    AppCollapseItem
  },
  data() {
    return {
      FAQS_STORE_MODULE_NAME: 'faqModule',
    }
  },
  computed: {
    faqs() {
      return this.$store.state[this.FAQS_STORE_MODULE_NAME].faqs
    }
  },
  created() {
    if (!store.hasModule(this.FAQS_STORE_MODULE_NAME)) store.registerModule(this.FAQS_STORE_MODULE_NAME, FaqModule)
    this.getFaqs()
  },
  methods: {
    getFaqs() {
      this.$vs.loading()
      this.$store.dispatch(`${this.FAQS_STORE_MODULE_NAME}/getItems`, 'status=1')
          .then(() => {
            this.$vs.loading.close()
          })
          .catch(error => {
            this.$root.$emit('getError', error)
            this.$vs.loading.close()
          })
    }
  }
}
</script>

<style lang="scss">
#faq {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  .page-content{
    width: 70%!important;
    margin: 0 auto;
  }

  .card {
    .card-header {
      display: flex;
    }
  }

  .map-column {
    height: 100%;
  }

  .page-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 15px;

    .login {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000;
      font-weight: 600;
      margin-top: 5px;
      border-bottom: 2px solid #39a164;
      padding: 5px;

      svg {
        margin-right: 0.5rem;
      }
    }
  }
}

@media (max-width: 768px) {

  #faq {
    height: auto;

    .page-content {
      width: 95% !important;
    }

    .info-column {
      padding: 0!important;
    }
  }
}

[dir=ltr] .collapse-icon [data-toggle=collapse]:after {
  left: 1rem;
}
[dir] .collapse-icon [data-toggle=collapse]:after {
  background-image: url('/assets/icons/minus-solid.svg');
  top: 54%;
}
[dir=ltr] .collapse-icon [aria-expanded=false]:after {
  background-image: url('/assets/icons/plus-solid.svg');
  transform: none;
}

#faq {
  .page-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #000;
    font-size: 15px;
    border-bottom: 2px solid var(--primary);
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    text-align: center;
  }
  #helpAccordion {
    .card-header {
      span {
        color: #000;
        font-weight: bold;
        font-size: 14px;
        margin-left: 25px;
      }
    }
    ul {
      margin: 0;
      padding: 0 30px;
    }
  }
}
</style>
